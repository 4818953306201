// @flow
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate, globalHistory } from '@reach/router';

import ClientSearch from '../components/ClientSearch';
import Layout from '../layouts/DefaultLayout';
import { pagesToMap, edgeToSearchContentType } from '../../utils';
import SEO from '../components/SEO';

type Props = {
  data: {
    allWordpressPage: { edges: Array<{ node: any }> },
    allWordpressPost: { edges: Array<{ node: any }> },
    allWordpressWpGuides: { edges: Array<{ node: any }> },
    allWordpressWpAlternatives: { edges: Array<{ node: any }> },
    allWordpressWpAlternativeCategories: { edges: Array<{ node: any }> },
    allWordpressWpAlternativeTags: { edges: Array<{ node: any }> },
  },
};

const SearchContainer = styled.div`
  max-width: 600px;
  margin: auto;
  
  .cancel-button, .search-icon {
    display: none;
  }
`;

function getSearchQuery() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('s');
}

const SearchTemplate = (props: Props) => {
  const { data } = props;
  const {
    allWordpressPage,
    allWordpressPost,
    allWordpressWpGuides,
    allWordpressWpAlternatives,
    allWordpressWpAlternativeCategories,
    allWordpressWpAlternativeTags,
  } = data;

  const pagesMap = useMemo(() => {
    // Change structure to match other content types
    const alternativeCategoriesEdges = allWordpressWpAlternativeCategories.edges.map((cat) => {
      const edge = {
        node: {
          ...cat.node,
          image: cat.node.acf ? cat.node.acf.image : null,
        },
      };
      delete edge.node.acf;

      return edge;
    });

    // Gather Array of all pages for search
    const searchDataSettings = { includeImages: true, includeContent: true };
    const allPages = [
      ...allWordpressPage.edges.map(edgeToSearchContentType('Page'), searchDataSettings),
      ...allWordpressPost.edges.map(edgeToSearchContentType('Blog Article', searchDataSettings)),
      ...allWordpressWpGuides.edges.map(edgeToSearchContentType('Guide', searchDataSettings)),
      ...allWordpressWpAlternatives.edges.map(edgeToSearchContentType('Alternative', searchDataSettings)),
      ...alternativeCategoriesEdges.map(edgeToSearchContentType('Alternatives Category', { includeImages: true })),
      ...allWordpressWpAlternativeTags.edges.map(edgeToSearchContentType('Alternatives Tag', { })),
    ];

    return pagesToMap(allPages);
  }, []);

  const [currentSearchQuery, setCurrentSearchQuery] = useState<string>('');

  // Fill initial search query from URL
  useEffect(() => {
    const searchQuery = getSearchQuery();
    if (searchQuery !== currentSearchQuery) {
      setCurrentSearchQuery(searchQuery || '');
    }
  }, []);

  // Update the URL when query changes
  const queryUpdated = useCallback((newSearchQuery) => {
    const searchQuery = getSearchQuery();
    if (searchQuery !== newSearchQuery) {
      navigate(`?s=${newSearchQuery}`);
    }
  }, []);

  // Check for history updates changing the URL
  useEffect(() => globalHistory.listen(() => {
    const searchQuery = getSearchQuery();
    if (searchQuery !== currentSearchQuery) {
      setCurrentSearchQuery(searchQuery || '');
    }
  }), [currentSearchQuery]);

  return (
    <Layout>
      <SEO
        title={`${currentSearchQuery ? `You searched for ${currentSearchQuery}` : 'Search'} - ethical.net`}
        canonical={null}
        meta={[
          {
            name: 'robots',
            content: 'noindex,follow',
          },
        ]}
      />

      <SearchContainer>
        <h1>Search</h1>

        <ClientSearch
          pagesMap={pagesMap}
          showImages
          indexContent
          searchQuery={currentSearchQuery}
          queryUpdated={queryUpdated}
        />
      </SearchContainer>
    </Layout>
  );
};

export const searchImageFragment = graphql`
  fragment SearchImage on wordpress__wp_media {
    localFile {
      childImageSharp {
        fixed(width: 160, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export default () => (
  <StaticQuery
    query={graphql`
      query SearchData {
        allWordpressPage {
          edges {
            node {
              path
              title
              excerpt
              content
              image: featured_media {
                ...SearchImage
              }
            }
          }
        }
        allWordpressPost {
          edges {
            node {
              path
              title
              excerpt
              content
              image: featured_media {
                ...SearchImage
              }
            }
          }
        }
        allWordpressWpGuides {
          edges {
            node {
              path
              title
              excerpt
              content
              image: featured_media {
                ...SearchImage
              }
            }
          }
        }
        allWordpressWpAlternatives {
          edges {
            node {
              path
              title
              excerpt
              content
              image: featured_media {
                ...SearchImage
              }
            }
          }
        }
        allWordpressWpAlternativeCategories {
          edges {
            node {
              path
              title: name
              excerpt: description
              acf {
                image: featured_image {
                  ...SearchImage
                }
              }
            }
          }
        }
        allWordpressWpAlternativeTags {
          edges {
            node {
              path
              title: name
              excerpt: description
            }
          }
        }
      }
    `}
    render={(data) => <SearchTemplate data={data} />}
  />
);
